import React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import ContactPage from "src/components/pages/contact/ContactPage";
import useWindowSize from "src/components/hooks/useWindowSize";
import Patrons from "src/components/layout/Patrons/Patrons";

export default function index() {
    const { width }: any = useWindowSize();

    return (
        <Layout>
            <Seo />
            <ContactPage />
            <ContactFooter showForm={false} showInfo={false} className="noRound" />
            <Patrons />
        </Layout>
    );
}
