import React from 'react';
import useWindowSize from "src/components/hooks/useWindowSize";
import Form from 'src/components/layout/ContactFooter/Form/Form';
import * as styles from "./ContactPage.module.scss";


const ContactPage = () => {
    const { width }: any = useWindowSize();


    return (
        <section className={styles.Section}>
            <div className={styles.Content}>
                <h2>Poznaj Hexar</h2>
                <p>Zostaw swojego maila, umów się na demo naszej platformy i dowiedz się co możemy zaoferować Twojej firmie.
                    Możesz również napisać bezpośrednio na <span>hello@hexar.tech</span>.</p>
            </div>
            <Form className="notAbsolute" />
        </section>
    )
}

export default ContactPage;
